<template>
  <EditRule class="info-block_wrapper_size" />
</template>

<script>
import EditRule from '@/components/services/call_back/EditRule.vue';

export default {
  components: {
    EditRule
  }
};
</script>

<style></style>
