<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
    <base-service>
      <template #title>
        Изменение правила обратного звонка
      </template>
      <template #body>
        <b-form class="info-block_color">
          <b-row>
            <b-col cols="12">
              <label>
                Номер телефона, который отобразится, как номер вызывающего абонента (АОН)
              </label>
            </b-col>
            <b-col sm="4">
              <b-form-select
                v-model="currentRule.CustomerAni"
                :options="optionsNumber"
                value-field="number"
                text-field="number"
                :state="numberCall"
                aria-describedby="number-call"
              >
              </b-form-select>
              <b-form-invalid-feedback id="number-call-live-feedback">
                Не выбран номер
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col cols="12">
              <label>Номер телефона оператора, на который реально будет направлен вызов</label>
            </b-col>
            <b-col sm="4">
              <InputPhone
                v-model="redirectNumber"
                :state="numberRedirect"
                aria-describedby="number-redirect"
              />
              <b-form-invalid-feedback id="number-redirect-live-feedback">
                Укажите номер в формате +7 (ххх) ххх-хх-хх
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col cols="12">
              <label>Название группы правил</label>
            </b-col>
            <b-col sm="4">
              <BaseTextInput
                v-model="currentRule.Key"
                :state="ruleName"
                aria-describedby="ruleName-feedback"
              />
              <br />
              <b-form-invalid-feedback id="ruleName-feedback">
                Название правила должно содержать не менее 3-х символов
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Дни, когда действует правило"
                label-for="days"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="days"
                  v-model="selectedDays"
                  :options="optionsDays"
                  :value="selectedDays"
                  :aria-describedby="ariaDescribedby"
                  name="active_days"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label>
                Время начала действия правила
              </label>
            </b-col>
            <b-col sm="4">
              <b-form-timepicker
                id="timepicker-start"
                v-model="currentRule.StartTime"
                show-seconds
                now-button
                reset-button
                locale="ru"
              ></b-form-timepicker>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col cols="12">
              <label>
                Время окончания действия правила
              </label>
            </b-col>
            <b-col sm="4">
              <b-form-timepicker
                id="timepicker-end"
                v-model="currentRule.EndTime"
                show-seconds
                now-button
                reset-button
                locale="ru"
              ></b-form-timepicker>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col cols="12">
              <label>
                Адрес электронной почты для уведомлений (оставьте пустым, если не требуется)
              </label>
            </b-col>
            <b-col sm="4">
              <BaseTextInput v-model="currentRule.Email"/>
            </b-col>
          </b-row>
          <br />
          <BaseButton @click.stop.prevent="updateRule" :disabled="!valid">
            <template #text>Изменить</template>
          </BaseButton>
        </b-form>
        <br />
        <base-back-button>
          <template #text>Назад к списку</template>
        </base-back-button>
      </template>
    </base-service>
    </slot>
  </the-wrapper>
</template>

<script>
import BaseService from '@/components/shared/BaseService.vue';
import baseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseBackButton from '@/components/shared/buttons/BaseBackButton.vue';
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { sendCustomerRequest } from '@/api/common';
import { getRuleOptions, getUserNumbers, UPDATE_URL } from '@/api/callback';
import {
  employMaskPhone,
  deleteMaskPhone,
  catchFunction,
  processResponse
} from '@/additional_helpers/helpers';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      optionsNumber: [],
      optionsDays: [
        { text: 'Пн', value: 1 },
        { text: 'Вт', value: 2 },
        { text: 'Ср', value: 4 },
        { text: 'Чт', value: 8 },
        { text: 'Пт', value: 16 },
        { text: 'Сб', value: 32 },
        { text: 'Вс', value: 64 },
        { text: 'Праздники', value: 128 }
      ],
      redirectNumber: '',
      selectedDays: [],
      currentRule: {}
    };
  },

  components: {
    BaseService,
    baseButton,
    BaseBackButton,
    InputPhone,
    BaseTextInput,
    TheWrapper
  },

  computed: {
    valid() {
      return this.numberCall && this.numberRedirect && this.ruleName;
    },

    numberCall() {
      return !!this.currentRule.CustomerAni;
    },

    numberRedirect() {
      return !!this.redirectNumber.length && this.redirectNumber.length > 17;
    },

    ruleName() {
      return !!this.currentRule.Key.length && this.currentRule.Key.length > 2;
    }
  },

  created() {
    Promise.all([getRuleOptions(this.$route.query.id), getUserNumbers()])
      .then((result) => {
        const [rule, numbers] = result;
        this.currentRule = rule;
        this.redirectNumber = employMaskPhone(rule.OperatorPhoneNumber);
        this.includesFlagServices(rule.DayOfWeek, this.selectedDays);
        this.optionsNumber = numbers;
        this.optionsNumber = numbers.map((el) => ({ number: el }));
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' }),

    // отмечает checkbox по активным дням недели
    includesFlagServices(ServicesFlag, days = []) {
      const mask = [1, 2, 4, 8, 16, 32, 64, 128];
      mask.forEach((day) => {
        // eslint-disable-next-line no-bitwise
        if ((day & ServicesFlag) > 0) {
          days.push(day);
        }
      });
      this.selectedDays = days;
      return this.selectedDays;
    },

    updateRule() {
      this.currentRule.OperatorPhoneNumber = deleteMaskPhone(this.redirectNumber);
      if (this.selectedDays.length) {
        this.currentRule.DayOfWeek = this.selectedDays.reduce((curr, prev) => prev + curr);
      }

      sendCustomerRequest(UPDATE_URL, JSON.stringify(this.currentRule))
        .then((result) => {
          processResponse(result);
          this.$router.push({
            name: 'Callback'
          });
        })
        .catch((e) => catchFunction(e));
    }
  }
};
</script>

<style lang="scss"></style>
